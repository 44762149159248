import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";

const PortfolioPage = () => {
	const data = useStaticQuery(graphql`
		query {
			allFile(
				filter: { absolutePath: { regex: "/images/portfolio/" } }
				limit: 30
			) {
				edges {
					node {
						id
						childImageSharp {
							gatsbyImageData(
								quality: 100
								placeholder: BLURRED
								formats: [AUTO, WEBP]
							)
						}
					}
				}
			}
			heroImg: file(relativePath: { eq: "home-hero-2.jpg" }) {
				publicURL
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
		}
	`);

	return (
		<Layout>
			<GatsbySeo
				title="My portfolio | Sarah Bennett Commercial Photography"
				description="View my commercial photography portfolio from Food, Personal Branding, Business Branding and Product Photography"
				language="en"
				canonical="https://www.sarah-bennett.com/portfolio"
				openGraph={{
					url: "https://www.sarah-bennett.com/portfolio",
					title: "My portfolio | Sarah Bennett Commercial Photography",
					description:
						"View my commercial photography portfolio from Food, Personal Branding, Business Branding and Product Photography",
					images: [
						{
							url: `${data.heroImg.publicURL}`,
							width: 800,
							height: 600,
							alt: "My commercial photography portfolio",
						},
					],
				}}
			/>

			<Container>
				<Row>
					<Col className="text-center my-5">
						<h1>Portfolio Pictures</h1>
						<hr className="w-25 mx-auto" />
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<Row>
							{data.allFile.edges.map(function (img, index) {
								if (index < 11) {
									return (
										<Col className="pb-3" xs={12}>
											<Card className="border-0" key={img.node.id}>
												<Card.Img
													as={GatsbyImage}
													image={img.node.childImageSharp.gatsbyImageData}
												/>
											</Card>
										</Col>
									);
								}
							})}
						</Row>
					</Col>
					<Col md={4}>
						<Row>
							{data.allFile.edges.map(function (img, index) {
								if (index > 10 && index < 20) {
									return (
										<Col className="pb-3 " xs={12}>
											<Card className="border-0" key={img.node.id}>
												<Card.Img
													as={GatsbyImage}
													image={img.node.childImageSharp.gatsbyImageData}
												/>
											</Card>
										</Col>
									);
								}
							})}
						</Row>
					</Col>
					<Col md={4}>
						<Row>
							{data.allFile.edges.map(function (img, index) {
								if (index > 19) {
									return (
										<Col className="pb-3 " xs={12}>
											<Card className="border-0" key={img.node.id}>
												<Card.Img
													as={GatsbyImage}
													image={img.node.childImageSharp.gatsbyImageData}
												/>
											</Card>
										</Col>
									);
								}
							})}
						</Row>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};

export default PortfolioPage;
